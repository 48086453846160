<template>
  <div class="box-error">
    {{ boxOpenedCatchError.status }}
  </div>
</template>

<script>
export default {
  name: 'box-error',
  props: {
    boxOpenedCatchError: {
      type: Object,
    },
  },
  data() {
    return {
    };
  },
};
</script>
